












































import { defineComponent } from '@vue/composition-api'
import TopBar from '@/components/organisms/o-top-bar.vue'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { RawLocation } from 'vue-router'
import store from '@/store'

declare interface TabItem {
  tab: string,
  route: RawLocation|null,
  disabled?: boolean,
  formId: string
}

export default defineComponent({
  name: 'TraineeForm',
  components: {
    TopBar,
  },
  data () {
    return {
      title: this.$t('trainee.title'),
      tab: 0,
      pages: [] as Array<TabItem>,
    }
  },
  computed: {
    ...mapState('traineeForm', {
      isTraineeSubmited: 'isSubmitted',
      isTraineeChanged: 'isChanged',
      trainee: 'trainee',
    }),
    ...mapState('needTrainingForm', {
      isNeedTrainingSubmited: 'isSubmitted',
      isNeedTrainingChanged: 'isChanged',
      needTraining: 'needTraining',
    }),
    ...mapGetters('navigation', {
      returnPreviousRoute: 'getReturnPreviousRoute',
    }),
    isSubmitted () {
      return this.isTraineeSubmited || this.isNeedTrainingSubmited
    },
    currentForm (): string {
      const currentRouteName = this.$route.name
      const currentPage: TabItem|undefined = this.pages.find((item) => {
        if (item.route) {
          if (typeof item.route === 'string') {
            return item.route === currentRouteName
          }
          return item.route.name === currentRouteName
        }
        return false
      })
      return currentPage ? currentPage.formId : ''
    },
  },
  async created () {
    await this.init()
  },
  watch: {
    $route () {
      this.init()
    },
  },
  methods: {
    async init () {
      const idNeedTraining = this.$route.params.idNeedTraining

      /**
       * Edit trainee or needTraining case
       */
      if (idNeedTraining) {
        if (!this.needTraining || this.needTraining.id !== parseInt(idNeedTraining)) {
          await this.loadNeedTrainingById(idNeedTraining)
        }
        if (this.trainee && this.trainee.id !== this.needTraining.trainee.id) {
          this.setTrainee(this.needTraining.trainee)
        }
        this.pages = [
          {
            tab: this.$t('trainee.form.tab.identity') as string,
            route: { name: 'Trainee Edit', params: { idNeedTraining } },
            formId: 'identificationForm',
          },
          {
            tab: this.$t('trainee.form.tab.formation') as string,
            route: { name: 'NeedTraining Edit', params: { idNeedTraining } },
            formId: 'formationForm',
          },
        ]
        return
      }

      /**
       * Create trainee case
       */
      this.resetTrainee()

      this.pages = [
        {
          tab: this.$t('trainee.form.tab.identity') as string,
          route: { name: 'Trainee Create' },
          formId: 'identificationForm',
        },
        {
          tab: this.$t('trainee.form.tab.formation') as string,
          route: { name: '404 Error' },
          disabled: true,
          formId: 'formationForm',
        },
      ]
    },
    ...mapActions('traineeForm', {
      loadTraineeById: 'loadById',
      resetTrainee: 'reset',
    }),
    ...mapActions('needTrainingForm', {
      loadNeedTrainingById: 'loadById',
    }),
    ...mapMutations('traineeForm', {
      setTrainee: 'setTrainee',
    }),
    returnPrevious () {
      if (this.returnPreviousRoute) {
        this.$router.push(this.returnPreviousRoute as RawLocation)
      } else {
        this.$router.push({ name: 'Trainee List' })
      }
    },
  },
  async beforeRouteUpdate (to, from, next) {
    store.commit('navigation/removeRoute')
    store.commit('navigation/addRoute', to)
    next()
  },
  async beforeRouteEnter (to, from, next) {
    store.commit('navigation/addRoute', to)
    next()
  },
  beforeRouteLeave (to, from, next) {
    if (to.name !== 'TrainingSession List Associate NeedTraining') {
      store.commit('navigation/removeRoute')
    }
    next()
  },
})
